html,
body {
  overflow: hidden !important;
  overscroll-behavior-y: none;
}

.to-top-button {
  width: 2vw;
  height: 2vw;
  min-width: 15px;
  min-height: 15px;
  position: absolute;
  top: 2%;
  right: 3%;
  z-index: 10;
  cursor: pointer;
}

.loading-screen {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 20;
  background-color: rgb(255, 255, 255);
  text-align: center;
}

.spinner-container {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
