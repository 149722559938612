/* SLIDE ANIMATIONS */
.slide-from-bottom {
  animation: slide-from-bottom 0.5s forwards;
  -webkit-animation: slide-from-bottom 0.5s forwards;
}

.slide-to-bottom {
  animation: slide-to-bottom 0.5s forwards;
  -webkit-animation: slide-to-bottom 0.5s forwards;
}

.slide-from-top {
  animation: slide-from-top 0.5s forwards;
  -webkit-animation: slide-from-top 0.5s forwards;
}

.slide-to-top {
  animation: slide-to-top 0.5s forwards;
  -webkit-animation: slide-to-top 0.5s forwards;
}

.slide-from-right {
  animation: slide-from-right 0.5s forwards;
  -webkit-animation: slide-from-right 0.5s forwards;
}

.slide-to-right {
  animation: slide-to-right 0.5s forwards;
  -webkit-animation: slide-to-right 0.5s forwards;
}

@keyframes slide-from-bottom {
  0% {
    transform: translateY(100vh);
  }
  100% {
    transform: translateY(0vh);
  }
}

@-webkit-keyframes slide-from-bottom {
  0% {
    transform: translateY(100vh);
  }
  100% {
    transform: translateY(0vh);
  }
}

@keyframes slide-from-top {
  0% {
    transform: translateY(-100vh);
  }
  100% {
    transform: translateY(0vh);
  }
}

@-webkit-keyframes slide-from-top {
  0% {
    transform: translateY(-100vh);
  }
  100% {
    transform: translateY(0vh);
  }
}

@keyframes slide-to-top {
  0% {
    transform: translateY(0vh);
  }
  100% {
    transform: translateY(-100vh);
  }
}

@-webkit-keyframes slide-to-top {
  0% {
    transform: translateY(0vh);
  }
  100% {
    transform: translateY(-100vh);
  }
}

@keyframes slide-to-bottom {
  0% {
    transform: translateY(0vh);
  }
  100% {
    transform: translateY(100vh);
  }
}

@-webkit-keyframes slide-to-bottom {
  0% {
    transform: translateY(0vh);
  }
  100% {
    transform: translateY(100vh);
  }
}

@keyframes slide-from-bottom {
  0% {
    transform: translateY(100vh);
  }
  100% {
    transform: translateY(0vh);
  }
}

@-webkit-keyframes slide-from-bottom {
  0% {
    transform: translateY(100vh);
  }
  100% {
    transform: translateY(0vh);
  }
}

@keyframes slide-from-right {
  0% {
    transform: translateX(100vw);
  }
  100% {
    transform: translateX(0);
  }
}

@-webkit-keyframes slide-from-right {
  0% {
    transform: translateX(100vw);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slide-to-right {
  0% {
    transform: translateX(0vw);
  }
  100% {
    transform: translateX(100vw);
  }
}

@-webkit-keyframes slide-to-right {
  0% {
    transform: translateX(0vw);
  }
  100% {
    transform: translateX(100vw);
  }
}

/* LINE APPEAR ANIMATIONS */
.slide-from-bottom div.line-appear,
.slide-from-top div.line-appear {
  animation: line-appear forwards 0.5s;
  animation-delay: 0.2s;
  --webkit-animation-delay: 0.2s;
  --webkit-animation: line-appear forwards 0.5s;
}

@keyframes line-appear {
  100% {
    width: 95%;
  }
}

@-webkit-keyframes line-appear {
  100% {
    width: 95%;
  }
}

.slide-to-bottom div.line-appear,
.slide-to-top div.line-appear {
  animation: line-disappear forwards 0.5s;
  --webkit-animation: line-appear forwards 0.5s;
}

@keyframes line-disappear {
  0% {
    width: 95%;
  }
  100% {
    width: 20%;
  }
}

/* APPEAR ANIMATIONS */
.slide-from-bottom div.animate-appear,
.slide-from-top div.animate-appear {
  animation: animate-appear forwards 0.5s;
  animation-delay: 0.5s;
  --webkit-animation: animate-appear forwards 0.5s;
  --webkit-animation-delay: 0.5s;
}

@keyframes animate-appear {
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes animate-appear {
  100% {
    opacity: 1;
  }
}

.slide-to-bottom div.animate-appear,
.slide-to-top div.animate-appear {
  animation: animate-disappear forwards 0.3s;
  --webkit-animation: animate-disappear forwards 0.3s;
}

@keyframes animate-disappear {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes animate-disappear {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* LETTER SPACING ANIMATIONS */
.slide-from-bottom div.animate-spacing,
.slide-from-top div.animate-spacing {
  animation: animate-spacing-expand forwards 0.5s;
  animation-delay: 0.5s;
  --webkit-animation-delay: 0.5s;
  --webkit-animation: animate-spacing-expand forwards 0.5s;
}

@keyframes animate-spacing-expand {
  100% {
    letter-spacing: 2vw;
    opacity: 1;
  }
}

@-webkit-keyframes animate-spacing-expand {
  100% {
    letter-spacing: 2vw;
    opacity: 1;
  }
}

.slide-to-bottom div.animate-spacing,
.slide-to-top div.animate-spacing {
  animation: animate-spacing-shrink forwards 0.5s;
  --webkit-animation: animate-spacing-shrink forwards 0.5s;
}

@keyframes animate-spacing-shrink {
  0% {
    opacity: 1;
    letter-spacing: 2vw;
  }
  100% {
    opacity: 0;
    letter-spacing: 0;
  }
}

@-webkit-keyframes animate-spacing-shrink {
  0% {
    opacity: 1;
    letter-spacing: 2vw;
  }
  100% {
    opacity: 0;
    letter-spacing: 0;
  }
}

/* BUTTON TRANSFORM ANIMATIONS */
