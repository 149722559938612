@import url(https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.frontpage-container {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  text-align: center;
  background-color: white;
  overflow: hidden;
}

.mobile-about,
.mobile-portfolio {
  display: none;
}

.hide-about-mask {
  width: 75vw;
  height: 100vh;
  position: fixed;
  left: 25vw;
  top: 0;
  z-index: 1000;
}

#frontpage-logo {
  width: 55%;
  height: auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

#about {
  font-family: "Playfair Display", serif;
  position: absolute;
  top: 50%;
  font-size: 2rem;
  cursor: pointer;
  z-index: 50;
}

#about {
  left: 0;
  transform: translateY(-50%) rotate(-90deg);
}

#scroll-arrow {
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%) rotate(-90deg);
  width: 2.5rem;
}

@media (max-width: 800px) {
  .hide-about-mask {
    display: none;
  }

  #frontpage-logo {
    width: 100%;
    height: auto;
  }

  #about,
  #scroll-arrow {
    display: none;
  }

  .mobile-about,
  .mobile-portfolio {
    display: block;
    position: fixed;
    font-family: "Playfair Display", serif;
    font-size: 4vw;
    transform: translateX(-50%);
    left: 50%;
    z-index: 1000;
    letter-spacing: 2vw;
  }

  .mobile-about {
    top: 3%;
  }

  .mobile-portfolio {
    bottom: 3%;
  }

  #mobile-about-arrow,
  #mobile-portfolio-arrow {
    width: 5vw;
    height: auto;
  }

  #mobile-about-arrow {
    transform: rotate(90deg);
  }

  .mobile-portfolio-title {
    margin-bottom: 7%;
  }

  #mobile-portfolio-arrow {
    transform: rotate(270deg);
  }
}

/* slide animations */
.slide-in {
  animation: slide-in 0.7s forwards;
  -webkit-animation: slide-in 0.7s forwards;
}

.slide-out {
  animation: slide-out 0.7s forwards;
  -webkit-animation: slide-out 0.7s forwards;
}

@keyframes slide-in {
  100% {
    transform: translateX(0%);
  }
}

@-webkit-keyframes slide-in {
  100% {
    transform: translateX(0%);
  }
}

@keyframes slide-out {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@-webkit-keyframes slide-out {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

/* main content */
#about-container {
  width: 25vw;
  height: 100vh;
  background-color: lightgray;
  position: absolute;
  transform: translateX(-100%);
  z-index: 100;
}

.about-title {
  font-size: 2rem;
  margin: 5vh 0 5vh 8%;
}

.about-description {
  font-style: italic;
  margin-bottom: 4vh;
  margin-left: 8%;
  width: 85%;
}

.about-line {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  border-top: 1px solid black;
  height: 1px;
  margin-bottom: 5vh;
}

.about-links-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 20px;
}

.shit {
  width: 40px;
  height: auto;
  margin: 0 10px;
}

.stack-left,
.stack-right {
  display: inline-block;
  vertical-align: top;
}

.stack-left {
  margin: 0 20% 0 8%;
}

.stack-title {
  margin-bottom: 1.5vh;
  font-size: 1.7vh;
  font-family: "Playfair Display", serif;
  letter-spacing: 0.3vw;
  font-weight: bold;
}

.stack,
.tech {
  line-height: 150%;
}

/* Mobile */
@media (max-width: 800px) {
  #about-container {
    width: 100vw;
    height: 100vh;
    background-color: lightgray;
    position: absolute;
    transform: translateY(-100%);
    z-index: 100;
  }

  .stack-title {
    font-size: 2vh;
  }

  .stack,
  .tech {
    font-size: 2vh;
  }

  /* Mobile slide animations */
  @keyframes slide-in {
    100% {
      transform: translateY(0%);
    }
  }

  @-webkit-keyframes slide-in {
    100% {
      transform: translateY(0%);
    }
  }

  @keyframes slide-out {
    0% {
      transform: translateY(0%);
    }
    100% {
      transform: translateY(-100%);
    }
  }

  @-webkit-keyframes slide-out {
    0% {
      transform: translateY(0%);
    }
    100% {
      transform: translateY(-100%);
    }
  }
}

html,
body {
  overflow: hidden !important;
  overscroll-behavior-y: none;
}

.to-top-button {
  width: 2vw;
  height: 2vw;
  min-width: 15px;
  min-height: 15px;
  position: absolute;
  top: 2%;
  right: 3%;
  z-index: 10;
  cursor: pointer;
}

.loading-screen {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 20;
  background-color: rgb(255, 255, 255);
  text-align: center;
}

.spinner-container {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

/* SLIDE ANIMATIONS */
.slide-from-bottom {
  animation: slide-from-bottom 0.5s forwards;
  -webkit-animation: slide-from-bottom 0.5s forwards;
}

.slide-to-bottom {
  animation: slide-to-bottom 0.5s forwards;
  -webkit-animation: slide-to-bottom 0.5s forwards;
}

.slide-from-top {
  animation: slide-from-top 0.5s forwards;
  -webkit-animation: slide-from-top 0.5s forwards;
}

.slide-to-top {
  animation: slide-to-top 0.5s forwards;
  -webkit-animation: slide-to-top 0.5s forwards;
}

.slide-from-right {
  animation: slide-from-right 0.5s forwards;
  -webkit-animation: slide-from-right 0.5s forwards;
}

.slide-to-right {
  animation: slide-to-right 0.5s forwards;
  -webkit-animation: slide-to-right 0.5s forwards;
}

@keyframes slide-from-bottom {
  0% {
    transform: translateY(100vh);
  }
  100% {
    transform: translateY(0vh);
  }
}

@-webkit-keyframes slide-from-bottom {
  0% {
    transform: translateY(100vh);
  }
  100% {
    transform: translateY(0vh);
  }
}

@keyframes slide-from-top {
  0% {
    transform: translateY(-100vh);
  }
  100% {
    transform: translateY(0vh);
  }
}

@-webkit-keyframes slide-from-top {
  0% {
    transform: translateY(-100vh);
  }
  100% {
    transform: translateY(0vh);
  }
}

@keyframes slide-to-top {
  0% {
    transform: translateY(0vh);
  }
  100% {
    transform: translateY(-100vh);
  }
}

@-webkit-keyframes slide-to-top {
  0% {
    transform: translateY(0vh);
  }
  100% {
    transform: translateY(-100vh);
  }
}

@keyframes slide-to-bottom {
  0% {
    transform: translateY(0vh);
  }
  100% {
    transform: translateY(100vh);
  }
}

@-webkit-keyframes slide-to-bottom {
  0% {
    transform: translateY(0vh);
  }
  100% {
    transform: translateY(100vh);
  }
}

@keyframes slide-from-bottom {
  0% {
    transform: translateY(100vh);
  }
  100% {
    transform: translateY(0vh);
  }
}

@-webkit-keyframes slide-from-bottom {
  0% {
    transform: translateY(100vh);
  }
  100% {
    transform: translateY(0vh);
  }
}

@keyframes slide-from-right {
  0% {
    transform: translateX(100vw);
  }
  100% {
    transform: translateX(0);
  }
}

@-webkit-keyframes slide-from-right {
  0% {
    transform: translateX(100vw);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slide-to-right {
  0% {
    transform: translateX(0vw);
  }
  100% {
    transform: translateX(100vw);
  }
}

@-webkit-keyframes slide-to-right {
  0% {
    transform: translateX(0vw);
  }
  100% {
    transform: translateX(100vw);
  }
}

/* LINE APPEAR ANIMATIONS */
.slide-from-bottom div.line-appear,
.slide-from-top div.line-appear {
  -webkit-animation: line-appear forwards 0.5s;
          animation: line-appear forwards 0.5s;
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
  --webkit-animation-delay: 0.2s;
  --webkit-animation: line-appear forwards 0.5s;
}

@keyframes line-appear {
  100% {
    width: 95%;
  }
}

@-webkit-keyframes line-appear {
  100% {
    width: 95%;
  }
}

.slide-to-bottom div.line-appear,
.slide-to-top div.line-appear {
  -webkit-animation: line-disappear forwards 0.5s;
          animation: line-disappear forwards 0.5s;
  --webkit-animation: line-appear forwards 0.5s;
}

@-webkit-keyframes line-disappear {
  0% {
    width: 95%;
  }
  100% {
    width: 20%;
  }
}

@keyframes line-disappear {
  0% {
    width: 95%;
  }
  100% {
    width: 20%;
  }
}

/* APPEAR ANIMATIONS */
.slide-from-bottom div.animate-appear,
.slide-from-top div.animate-appear {
  -webkit-animation: animate-appear forwards 0.5s;
          animation: animate-appear forwards 0.5s;
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
  --webkit-animation: animate-appear forwards 0.5s;
  --webkit-animation-delay: 0.5s;
}

@keyframes animate-appear {
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes animate-appear {
  100% {
    opacity: 1;
  }
}

.slide-to-bottom div.animate-appear,
.slide-to-top div.animate-appear {
  -webkit-animation: animate-disappear forwards 0.3s;
          animation: animate-disappear forwards 0.3s;
  --webkit-animation: animate-disappear forwards 0.3s;
}

@keyframes animate-disappear {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes animate-disappear {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* LETTER SPACING ANIMATIONS */
.slide-from-bottom div.animate-spacing,
.slide-from-top div.animate-spacing {
  -webkit-animation: animate-spacing-expand forwards 0.5s;
          animation: animate-spacing-expand forwards 0.5s;
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
  --webkit-animation-delay: 0.5s;
  --webkit-animation: animate-spacing-expand forwards 0.5s;
}

@keyframes animate-spacing-expand {
  100% {
    letter-spacing: 2vw;
    opacity: 1;
  }
}

@-webkit-keyframes animate-spacing-expand {
  100% {
    letter-spacing: 2vw;
    opacity: 1;
  }
}

.slide-to-bottom div.animate-spacing,
.slide-to-top div.animate-spacing {
  -webkit-animation: animate-spacing-shrink forwards 0.5s;
          animation: animate-spacing-shrink forwards 0.5s;
  --webkit-animation: animate-spacing-shrink forwards 0.5s;
}

@keyframes animate-spacing-shrink {
  0% {
    opacity: 1;
    letter-spacing: 2vw;
  }
  100% {
    opacity: 0;
    letter-spacing: 0;
  }
}

@-webkit-keyframes animate-spacing-shrink {
  0% {
    opacity: 1;
    letter-spacing: 2vw;
  }
  100% {
    opacity: 0;
    letter-spacing: 0;
  }
}

/* BUTTON TRANSFORM ANIMATIONS */

.portfolio-item-container {
  position: absolute;
  background-color: white;
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.portfolio-item-on-bottom {
  transform: translateY(100vh);
}

.portfolio-item-on-top {
  transform: translateY(-100vh);
}

.animate-appear {
  opacity: 0;
}

/* MAIN CONTENT */
.portfolio-item-picture-container {
  top: 0;
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  text-align: center;
}

.item-image {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  max-width: 80%;
  max-height: 80%;
}

.portfolio-item-mask {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: rgb(255, 255, 255);
  z-index: 2;
}

.portfolio-item-index {
  margin: 1.5% 0 1% 2.5%;
  font-size: 2.3vw;
  font-family: serif;
}

#line {
  width: 0%;
  position: relative;
  left: 2.5%;
  border-top: 1px solid rgb(255, 255, 255);
  height: 1px;
}

.mask-expertise {
  position: relative;
  left: 2.5%;
  padding-top: 1rem;
  font-size: 23px;
  color: #ffdead;
}

.title-legend-container {
  position: absolute;
  bottom: 20%;
  margin-left: 2.5%;
}

.mask-title {
  font-size: 6vw;
  font-family: "Playfair Display", serif;
  letter-spacing: 0vw;
  font-weight: 50;
  opacity: 0;
}

.mask-legend {
  font-size: 2vw;
  font-weight: 100;
}

.mask-toggle-info-button {
  cursor: pointer;
  position: fixed;
  right: 5%;
  top: 50%;
  transform: translateY(-50%) rotate(180deg);
  background-color: rgb(151, 151, 151);
  border-radius: 50%;
  padding: 1vw;
  width: 1.5vw;
  height: 1.5vw;
  min-width: 20px;
  min-height: 20px;
  z-index: 2000;
}

.mask-toggle-info-button img {
  width: 100%;
  height: 100%;
}

/* DETAILS */
.portfolio-item-details {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgb(255, 255, 255);
  z-index: 20;
  width: 100vw;
  height: 100vh;
  transform: translateX(100vw);
}

#toggle-back {
  transform: rotate(180deg);
}

.details-picture-container,
.details-container {
  width: 100%;
  height: 50%;
}

.details-picture-container {
  background-color: rgb(211, 211, 211);
  position: relative;
  overflow: hidden;
}

.details-image {
  max-height: 90%;
  max-width: 90%;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.details-title {
  color: rgb(151, 151, 151);
  margin-bottom: 4px;
  font-family: "Playfair Display", serif;
}

.details-body {
  margin-bottom: 1.5rem;
}

.details-content {
  padding-left: 1rem;
  width: 50%;
  position: relative;
  left: 50%;
  top: 20%;
  transform: translateX(-50%);
  border-left: 1px solid rgb(255, 165, 0);
}

.mobile-details-content {
  display: none;
}

.details-left,
.details-right {
  display: inline-block;
  vertical-align: top;
}

.details-left {
  width: 40%;
}

.details-right {
  width: 60%;
}

.details-link {
  text-decoration: none;
  display: block;
  margin-top: 1vh;
}

#item-image-fdf {
  min-width: 100%;
  height: 100%;
  overflow: hidden;
  max-width: 120%;
  max-height: 120%;
}

#details-image-fdf {
  width: 160%;
  height: auto;
  max-height: none;
  max-width: none;
}

@media (max-width: 800px) {
  .portfolio-item-index {
    font-size: 6vw;
    margin-top: 5%;
  }

  .mask-expertise {
    font-size: 1.8vh;
    font-weight: 100;
    padding-top: 1vh;
  }

  .mask-title {
    font-size: 8vw;
  }

  .mask-legend {
    font-size: 4vw;
  }

  .mask-toggle-info-button {
    background-color: transparent;
    transform: rotate(180deg);
  }

  .item-image {
    max-width: 95%;
    max-height: 95%;
  }

  .details-content {
    display: none;
  }

  #toggle-back {
    display: none;
  }

  .mobile-details-content {
    display: block;
    padding: 5% 0 5% 10%;
  }

  .details-picture-container {
    height: 35%;
  }

  .details-container {
    height: 65%;
  }

  .details-title,
  .details-body {
    font-family: unset;
    font-size: 3.5vw;
    min-height: 2vh;
    margin-bottom: 8px;
  }

  .details-line {
    width: 100%;
    position: relative;
    left: -5%;
    border-top: 1px solid rgb(211, 211, 211);
    margin-bottom: 10%;
  }

  .details-left,
  .details-right {
    width: auto;
  }

  .details-left {
    margin-right: 15%;
  }

  .mobile-details-description {
    margin: 5% 0 10% 0;
    width: 80%;
  }

  .mobile-details-description .details-title {
    display: none;
  }

  .mobile-details-description .details-body {
    color: rgb(128, 128, 128);
    font-style: italic;
  }

  .details-link {
    font-weight: 700;
    color: rgb(24, 3, 145);
  }

  #details-image-fdf {
    height: 250% !important;
  }
}

