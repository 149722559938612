/* slide animations */
.slide-in {
  animation: slide-in 0.7s forwards;
  -webkit-animation: slide-in 0.7s forwards;
}

.slide-out {
  animation: slide-out 0.7s forwards;
  -webkit-animation: slide-out 0.7s forwards;
}

@keyframes slide-in {
  100% {
    transform: translateX(0%);
  }
}

@-webkit-keyframes slide-in {
  100% {
    transform: translateX(0%);
  }
}

@keyframes slide-out {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@-webkit-keyframes slide-out {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

/* main content */
#about-container {
  width: 25vw;
  height: 100vh;
  background-color: lightgray;
  position: absolute;
  transform: translateX(-100%);
  z-index: 100;
}

.about-title {
  font-size: 2rem;
  margin: 5vh 0 5vh 8%;
}

.about-description {
  font-style: italic;
  margin-bottom: 4vh;
  margin-left: 8%;
  width: 85%;
}

.about-line {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  border-top: 1px solid black;
  height: 1px;
  margin-bottom: 5vh;
}

.about-links-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 20px;
}

.shit {
  width: 40px;
  height: auto;
  margin: 0 10px;
}

.stack-left,
.stack-right {
  display: inline-block;
  vertical-align: top;
}

.stack-left {
  margin: 0 20% 0 8%;
}

.stack-title {
  margin-bottom: 1.5vh;
  font-size: 1.7vh;
  font-family: "Playfair Display", serif;
  letter-spacing: 0.3vw;
  font-weight: bold;
}

.stack,
.tech {
  line-height: 150%;
}

/* Mobile */
@media (max-width: 800px) {
  #about-container {
    width: 100vw;
    height: 100vh;
    background-color: lightgray;
    position: absolute;
    transform: translateY(-100%);
    z-index: 100;
  }

  .stack-title {
    font-size: 2vh;
  }

  .stack,
  .tech {
    font-size: 2vh;
  }

  /* Mobile slide animations */
  @keyframes slide-in {
    100% {
      transform: translateY(0%);
    }
  }

  @-webkit-keyframes slide-in {
    100% {
      transform: translateY(0%);
    }
  }

  @keyframes slide-out {
    0% {
      transform: translateY(0%);
    }
    100% {
      transform: translateY(-100%);
    }
  }

  @-webkit-keyframes slide-out {
    0% {
      transform: translateY(0%);
    }
    100% {
      transform: translateY(-100%);
    }
  }
}
