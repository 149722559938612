.portfolio-item-container {
  position: absolute;
  background-color: white;
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.portfolio-item-on-bottom {
  transform: translateY(100vh);
}

.portfolio-item-on-top {
  transform: translateY(-100vh);
}

.animate-appear {
  opacity: 0;
}

/* MAIN CONTENT */
.portfolio-item-picture-container {
  top: 0;
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  text-align: center;
}

.item-image {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  max-width: 80%;
  max-height: 80%;
}

.portfolio-item-mask {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: rgb(255, 255, 255);
  z-index: 2;
}

.portfolio-item-index {
  margin: 1.5% 0 1% 2.5%;
  font-size: 2.3vw;
  font-family: serif;
}

#line {
  width: 0%;
  position: relative;
  left: 2.5%;
  border-top: 1px solid rgb(255, 255, 255);
  height: 1px;
}

.mask-expertise {
  position: relative;
  left: 2.5%;
  padding-top: 1rem;
  font-size: 23px;
  color: #ffdead;
}

.title-legend-container {
  position: absolute;
  bottom: 20%;
  margin-left: 2.5%;
}

.mask-title {
  font-size: 6vw;
  font-family: "Playfair Display", serif;
  letter-spacing: 0vw;
  font-weight: 50;
  opacity: 0;
}

.mask-legend {
  font-size: 2vw;
  font-weight: 100;
}

.mask-toggle-info-button {
  cursor: pointer;
  position: fixed;
  right: 5%;
  top: 50%;
  transform: translateY(-50%) rotate(180deg);
  background-color: rgb(151, 151, 151);
  border-radius: 50%;
  padding: 1vw;
  width: 1.5vw;
  height: 1.5vw;
  min-width: 20px;
  min-height: 20px;
  z-index: 2000;
}

.mask-toggle-info-button img {
  width: 100%;
  height: 100%;
}

/* DETAILS */
.portfolio-item-details {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgb(255, 255, 255);
  z-index: 20;
  width: 100vw;
  height: 100vh;
  transform: translateX(100vw);
}

#toggle-back {
  transform: rotate(180deg);
}

.details-picture-container,
.details-container {
  width: 100%;
  height: 50%;
}

.details-picture-container {
  background-color: rgb(211, 211, 211);
  position: relative;
  overflow: hidden;
}

.details-image {
  max-height: 90%;
  max-width: 90%;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.details-title {
  color: rgb(151, 151, 151);
  margin-bottom: 4px;
  font-family: "Playfair Display", serif;
}

.details-body {
  margin-bottom: 1.5rem;
}

.details-content {
  padding-left: 1rem;
  width: 50%;
  position: relative;
  left: 50%;
  top: 20%;
  transform: translateX(-50%);
  border-left: 1px solid rgb(255, 165, 0);
}

.mobile-details-content {
  display: none;
}

.details-left,
.details-right {
  display: inline-block;
  vertical-align: top;
}

.details-left {
  width: 40%;
}

.details-right {
  width: 60%;
}

.details-link {
  text-decoration: none;
  display: block;
  margin-top: 1vh;
}

#item-image-fdf {
  min-width: 100%;
  height: 100%;
  overflow: hidden;
  max-width: 120%;
  max-height: 120%;
}

#details-image-fdf {
  width: 160%;
  height: auto;
  max-height: none;
  max-width: none;
}

@media (max-width: 800px) {
  .portfolio-item-index {
    font-size: 6vw;
    margin-top: 5%;
  }

  .mask-expertise {
    font-size: 1.8vh;
    font-weight: 100;
    padding-top: 1vh;
  }

  .mask-title {
    font-size: 8vw;
  }

  .mask-legend {
    font-size: 4vw;
  }

  .mask-toggle-info-button {
    background-color: transparent;
    transform: rotate(180deg);
  }

  .item-image {
    max-width: 95%;
    max-height: 95%;
  }

  .details-content {
    display: none;
  }

  #toggle-back {
    display: none;
  }

  .mobile-details-content {
    display: block;
    padding: 5% 0 5% 10%;
  }

  .details-picture-container {
    height: 35%;
  }

  .details-container {
    height: 65%;
  }

  .details-title,
  .details-body {
    font-family: unset;
    font-size: 3.5vw;
    min-height: 2vh;
    margin-bottom: 8px;
  }

  .details-line {
    width: 100%;
    position: relative;
    left: -5%;
    border-top: 1px solid rgb(211, 211, 211);
    margin-bottom: 10%;
  }

  .details-left,
  .details-right {
    width: auto;
  }

  .details-left {
    margin-right: 15%;
  }

  .mobile-details-description {
    margin: 5% 0 10% 0;
    width: 80%;
  }

  .mobile-details-description .details-title {
    display: none;
  }

  .mobile-details-description .details-body {
    color: rgb(128, 128, 128);
    font-style: italic;
  }

  .details-link {
    font-weight: 700;
    color: rgb(24, 3, 145);
  }

  #details-image-fdf {
    height: 250% !important;
  }
}
